import makeBtnLoader from "../app/global-components/btn-loader.js";
import { rootDoc } from "./dom-selectors.js";
export default function makeFormRequest({ form, submitBtn }) {
    _preventSubmit();
    const LOADER = makeBtnLoader({ btn: submitBtn });
    LOADER.onLoading();
    return Object.freeze({
        afterResponse
    });
    function afterResponse(resetForm) {
        _enableSubmit();
        LOADER.reset();
        if (resetForm)
            form?.reset();
    }
    function _preventSubmit() {
        document.addEventListener('keydown', _onPreventFormSubmit);
        rootDoc.classList.add('no-pointers');
    }
    function _enableSubmit() {
        document.removeEventListener('keydown', _onPreventFormSubmit);
        rootDoc.classList.remove('no-pointers');
    }
    function _onPreventFormSubmit(e) {
        if (e.code === 'Enter')
            e.preventDefault(); //avoid submitting form
    }
}
