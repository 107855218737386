export function createSuccessSVG() {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
    svg.setAttribute('viewBox', '0 0 50 50');
    svg.setAttribute('width', '30');
    svg.setAttribute('height', '30');
    svg.setAttribute('transform', 'scale(0.7)');
    svg.innerHTML = `
        <path fill="#00bf6f"
            d="m25,0.572441c-13.489683,0 -24.427559,10.937876 -24.427559,24.427559c0,13.489683 10.937876,24.427559 24.427559,24.427559c13.489683,0 24.427559,-10.937876 24.427559,-24.427559c0,-13.489683 -10.937876,-24.427559 -24.427559,-24.427559zm12.530029,16.259594l-15.169078,19.465711c-0.119957,0.119957 -0.31625,0.381681 -0.556163,0.381681c-0.250819,0 -0.414396,-0.174483 -0.556163,-0.31625c-0.141767,-0.141767 -8.604171,-8.277017 -8.604171,-8.277017l-0.163577,-0.163577c-0.065431,-0.098146 -0.119957,-0.218103 -0.119957,-0.348965c0,-0.130862 0.054526,-0.250819 0.119957,-0.348965c0.043621,-0.043621 0.076336,-0.076336 0.119957,-0.130862c0.839697,-0.883318 2.540902,-2.671764 2.649954,-2.780816c0.141767,-0.141767 0.261724,-0.327155 0.523448,-0.327155c0.272629,0 0.447112,0.229008 0.577973,0.35987c0.130862,0.130862 4.907322,4.721934 4.907322,4.721934l12.137443,-15.594379c0.109052,-0.087241 0.239914,-0.152672 0.381681,-0.152672c0.141767,0 0.272629,0.054526 0.381681,0.141767l3.336979,2.628144c0.087241,0.109052 0.141767,0.239914 0.141767,0.381681c0.010905,0.141767 -0.043621,0.261724 -0.109052,0.35987z" />`;
    return svg;
}
export function createErrorSVG() {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
    svg.setAttribute('viewBox', '0 0 64 64');
    svg.setAttribute('width', '50');
    svg.setAttribute('height', '50');
    svg.innerHTML = `
        <path fill="#ff618a" d="M30.16,11.51,6.84,51.9a2.13,2.13,0,0,0,1.84,3.19H55.32a2.13,2.13,0,0,0,1.84-3.19L33.84,11.51A2.13,2.13,0,0,0,30.16,11.51Z"/>
        <path fill="#1e1f29" d="M29,46a3,3,0,1,1,3,3A2.88,2.88,0,0,1,29,46Zm1.09-4.66-.76-15h5.26l-.73,15Z"/>`;
    return svg;
}
